<template>
  <table class="table">
    <col style="width: 20%" />
    <col style="width: 40%" />
    <col style="width: 40%" />
    <thead class="table__head">
      <tr>
        <th>Rank</th>
        <th>Name</th>
        <th>District</th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr v-for="(userAnswer, index) in openGuessAnswers" :key="userAnswer._id">
        <td>
          <span>{{ index + 1 }}</span>
        </td>
        <td>{{ userAnswer.name }}</td>
        <td>{{ userAnswer.district }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "LeaderboardTable",
  props: ["openGuessAnswers"],
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  margin: 0 auto;

  font-family: "RobotoRegular", sans-serif;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: left;

  & td {
    padding-top: 4.4rem;

    &:first-child {
      font-family: "RobotoBold", sans-serif;
      font-weight: 700;
    }

    & span {
      display: inline-block;

      padding: 2.1rem 2.7rem;
      border-radius: 1rem;

      background: linear-gradient(43.29deg, #f15a24 -29.68%, #faac18 120.46%);
    }
  }
}

.table__head {
  border-bottom: 1px solid #e5e5e5;

  & th {
    padding: 2.1rem 0 3.1rem;

    font-family: "RobotoBold", sans-serif;
    font-weight: 700;
    font-size: 2.1rem;
    text-transform: uppercase;
  }
}

@media (max-width: 1440px) {
  .table {
    font-size: 4.5rem;

    & td {
      padding-top: 5.5rem;

      & span {
        padding: 2.6rem 3.4rem;
      }
    }
  }

  .table__head {
    & th {
      padding: 2.6rem 0 3.875rem;

      font-size: 2.6rem;
    }
  }
}

@media (max-width: 991px) {
  .table {
    font-size: 2rem;

    & td {
      padding-top: 3rem;

      & span {
        padding: 1.1rem 1.7rem;
      }
    }
  }

  .table__head {
    & th {
      padding: 1.1rem 0;

      font-size: 1.6rem;
    }
  }
}
</style>
