<template>
  <main class="leaderboard">
    <div class="container">
      <header class="leaderboard__header">
        <img class="leaderboard__logo" :src="logo" alt="Page logo" />
      </header>
      <section class="leaderboard__text">
        What were the total operational shrink dollars per minute in FY21? The
        closest guess wins a prize!
      </section>
      <section class="leaderboard__footer">
        <div class="blur" />
        <div class="leaderboard__content">
          <LeaderboardTable :openGuessAnswers="openGuessAnswers" />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import LeaderboardTable from "../components/LeaderboardTable";
import logo from "../assets/images/icons/logo-with-hand.svg";

export default {
  name: "Leaderboard",
  components: { LeaderboardTable },
  computed: {
    ...mapGetters(["openGuessAnswers"]),
  },
  data: () => ({
    logo,
  }),
  async mounted() {
    await this.$store.dispatch("getOpenGuessAnswers");
  },
};
</script>

<style scoped lang="scss">
.leaderboard {
  padding: 13.2rem 0 21.6rem;
  height: 100vh;

  overflow-y: scroll;
}

.container {
  width: 100%;
  max-width: 96.7rem;
  padding-inline: 1.5rem;
  margin-inline: auto;
}

.leaderboard__logo {
  display: block;

  max-width: 100%;
  height: auto;
  margin-inline: auto;
}

.leaderboard__text {
  margin: 8.2rem auto 13.6rem;
  max-width: 69.4rem;

  font-family: "RosarioBold", sans-serif;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 1.2;
}

.leaderboard__footer {
  position: relative;

  border-radius: 5rem;

  background: rgba(89, 103, 116, 0.47);
}

.leaderboard__content {
  position: relative;

  padding: 5.5rem 8rem 7.2rem 7.4rem;
}

.blur {
  position: absolute;

  width: 100%;
  height: 100%;
  border-radius: 5rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 5rem;

    backdrop-filter: blur(26px);
  }
}

@media (max-width: 1440px) {
  .leaderboard {
    padding-top: 16.5rem;
  }

  .container {
    max-width: 120rem;
  }

  .leaderboard__text {
    max-width: 86.75rem;
    font-size: 6rem;
  }
}

@media (max-width: 991px) {
  .leaderboard {
    padding: 4rem 0;
  }

  .leaderboard__logo {
    max-width: 45rem;
  }

  .leaderboard__text {
    margin-block: 3rem;

    font-size: 2.8rem;
  }

  .leaderboard__content {
    padding: 1.5rem 2rem 3.2rem 3.4rem;
  }
}
</style>
